<template>
    <el-dialog title="Perfect information" :visible.sync="dialogVisible" :width="width" :show-close="false"
        :close-on-click-modal="false" :custom-class="'apply-membership-dialog'">
        <div>
            <FormBoxVue :title="'Account'" :isRequired="true">
                <template #content>
                    <el-input v-model="formData.account" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Password'" :isRequired="true">
                <template #content>
                    <el-input v-model="formData.password" placeholder="" type="password"></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Email'" :isRequired="true">
                <template #content>
                    <el-input v-model="formData.email" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Journal'" :isRequired="false">
                <template #content>
                    <el-input v-model="formData.journal" placeholder=""></el-input>
                </template>
            </FormBoxVue>

            <FormBoxVue :title="'Phone'" :isRequired="false">
                <template #content>
                    <el-input v-model="formData.phone" placeholder=""></el-input>
                </template>
            </FormBoxVue>
        </div>

        <div slot="footer" class="dialog_footer">
            <el-button type="primary" @click="onDialogSubmit">Submit</el-button>
        </div>
    </el-dialog>
</template>

<script>
import $http from "@/request/http";
import FormBoxVue from "@/views/widget/components/formBox.vue"
import { bindUserInfo } from "@/api/widget"

export default {
    components: {
        FormBoxVue
    },
    data() {
        return {
            width: "40%",
            orcidInfo: null,
            formData: {
                account: "",
                password: "",
                email: "",
                journal: "",
                phone: "",
                oauthId: ""
            },
            dialogVisible: false
        }
    },
    methods: {
        getUserInfoById(id) {
            $http.getOrcidP('/contrimetric-user/simpleInfo', { id }, true).then(res => {
                const simUserInfo = res.data;
                localStorage.setItem('simUserInfo', JSON.stringify(simUserInfo));

                this.$message.success('Submit successfully');

                this.dialogVisible = false

                this.$loginDialog.closeDialog()
            }).catch(err => {
                console.log(err);
            })
        },
        onDialogSubmit() {
            if (this.validateForm()) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                const params = {
                    account: this.formData.account,
                    password: this.formData.password,
                    email: this.formData.email,
                    code: {
                        journal: this.formData.journal,
                    },
                    phone: this.formData.phone,
                    oauthId: this.orcidInfo.oauthId
                }

                bindUserInfo(params).then((res) => {
                    const localUserInfo = JSON.parse(localStorage.getItem("userInfo"))

                    if (localUserInfo) {
                        localUserInfo.userId = res.data.userId
                        localStorage.setItem("userInfo", JSON.stringify(localUserInfo))
                    }

                    loading.close();
                    this.getUserInfoById(res.data.id)
                }).catch(err => {
                    console.log(err);

                    this.$message.error(err || 'Unknown error')
                }).finally(() => {
                    loading.close()
                    this.clearFormData()
                })
            }
        },
        openDialog(row) {
            this.orcidInfo = row;
            this.formData.account = row.account

            this.dialogVisible = true
        },
        validateForm() {
            if (this.formData.account == '' || this.formData.password == '' || this.formData.email == '') {
                this.$message.warning("Please check the account or password or email")

                return false
            }
            return true
        },
        clearFormData() {
            for (let key in this.formData) {
                this.formData[key] = "";
            }
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.width = width < 768 ? '360px' : '40%';
        },
    },
    mounted() {
        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },
}
</script>

<style lang='scss'>
.apply-membership-dialog {
    .el-dialog__body {
        padding: 10px 30px;
    }
}
</style>
<template>
    <div>
        <el-dialog title="" :top="registrationShow ? '25vh' : '10vh'" :visible.sync="dialogVisible" :width="width"
            :close-on-click-modal="false" :custom-class="'apply-membership-dialog'">
            <div>
                <div :class="!registrationShow ? 'toLogin layui-hide' : 'toLogin'">
                    <el-form class="layui-form login-form" style="box-shadow: none;" action="" lay-filter="example1"
                        :rules="rules" :model="loginForm" ref="form">
                        <h2 style="margin-bottom: 30px;font-size: 30px;"><strong>Log in</strong></h2>
                        <el-form-item>
                            <div class="layui-input-block">
                                <input type="text" v-model="loginForm.eMail" name="username" lay-verify="required"
                                    lay-reqtext="Email required!" autocomplete="off" placeholder="Email"
                                    class="layui-input">
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="layui-input-block">
                                <input type="password" v-model="loginForm.userPassword" name="password"
                                    lay-verify="required" lay-reqtext="password required!" autocomplete="off"
                                    placeholder="password" class="layui-input">
                            </div>
                        </el-form-item>
                        <div class="orcid_register">
                            <div class="orcid_login">
                                <span>Or Login via:</span>
                                <img src="@/assets/images/orcid.png" alt="" @click="orcidLoginFun">
                            </div>
                            <p class="lost-your-password right float-hand-color noaccount" @click="registrationBtn">
                                Registration Now?</p>
                        </div>
                        <div>
                            <button type="button" class="layui-btn layui-btn-fluid" style="margin-top: 50px;"
                                @click="loginBtn" lay-submit="" lay-filter="demo1">Log In</button>
                        </div>
                    </el-form>
                </div>
                <div :class="registrationShow ? 'toSignUp layui-hide' : 'toSignUp'">
                    <form class="login-form" style="box-shadow: none;" action="" lay-filter="example2" :rules="rules"
                        ref="form">
                        <h2 style="margin-bottom: 30px;font-size: 30px;"><strong>Sign UP</strong></h2>
                        <h3 style="font-size: 20px;">Please select your identity</h3>
                        <div class="layui-form-item">
                            <div class="layui-input-block" style="display: flex">
                                <el-radio-group v-model="sel" style="margin-top: 10px;">
                                    <el-radio :label="item" v-for="(item, index) of radio" :key="index"
                                        @change="radioBtn(index)">{{ item }}</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <div class="layui-input-block">
                                <input type="text" name="userName" v-model="form.userName" autocomplete="off"
                                    placeholder="User Name" lay-verify="required" lay-reqtext="User Name required!"
                                    class="layui-input">
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <div class="layui-input-block">
                                <input type="text" name="eMail" v-model="form.eMail" autocomplete="off"
                                    placeholder="Email" lay-verify="required" lay-reqtext="Email required!"
                                    class="layui-input">
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <div class="layui-input-block">
                                <input type="text" name="PhoneNum" v-model="form.phone" autocomplete="off"
                                    placeholder="Phone" lay-verify="required" lay-reqtext="Phone required!"
                                    class="layui-input">
                            </div>
                        </div>
                        <div :class="radioIndex == 0 ? 'layui-form-item author' : 'layui-form-item author layui-hide'">
                            <div class="layui-input-block">
                                <input type="text" name="researchDirection" v-model="form.researchDirection"
                                    autocomplete="off" placeholder="Research direction"
                                    lay-reqtext="Research direction required!" class="layui-input">
                            </div>
                        </div>
                        <div :class="radioIndex == 0 ? 'editor layui-hide' : 'editor'">
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                    <input type="text" name="Publisher" v-model="form.publisher" autocomplete="off"
                                        placeholder="Publisher" lay-reqtext="Publisher required!" class="layui-input">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                    <input type="text" name="journal" v-model="form.journal" autocomplete="off"
                                        placeholder="Journal" lay-reqtext="Journal required!" class="layui-input">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                    <input type="text" name="userRole" v-model="form.role" autocomplete="off"
                                        placeholder="Role" lay-reqtext="Role required!" class="layui-input">
                                </div>
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <div class="layui-input-block">
                                <input type="password" name="userPassword" v-model="form.userPassword"
                                    lay-verify="required" lay-reqtext="password required!" autocomplete="off"
                                    placeholder="password" class="layui-input">
                            </div>
                        </div>

                        <div class="layui-row">
                            <div class="layui-col-md8">
                                <div class="layui-form-item">
                                    <div class="layui-input-block">
                                        <input type="text" name="verificationCode" v-model="form.verificationCode"
                                            autocomplete="off" lay-verify="required"
                                            lay-reqtext="Verification Code required!" placeholder="Verification Code"
                                            class="layui-input">
                                    </div>
                                </div>
                            </div>
                            <div class="layui-col-md4">
                                <button type="button" class="layui-btn countDown" style="height: 50px;min-width: 183px;"
                                    @click="getCode"> {{ isSend ? `Try again in ${timeCount} second` : btnText }}
                                </button>
                            </div>
                        </div>
                        <div>
                            <p class="lost-your-password right float-hand-color" @click="registrationShow = true">Log
                                In?
                            </p>
                        </div>

                        <div>
                            <button type="reset"
                                class="layui-btn layui-btn-primary layui-hide toSignUpReset">重置</button>
                            <button type="button" class="layui-btn layui-btn-fluid" lay-submit="" lay-filter="demo2"
                                style="margin-top: 50px;height: 50px;" @click="signBtn">Sign Up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </el-dialog>

        <NotOrcidDialog ref="notOrcidDialog"></NotOrcidDialog>
    </div>
</template>

<script>
import $http from "@/request/http";
import NotOrcidDialog from "./notOrcidDialog.vue";
import Crypto from "crypto-js";
import { orcidLogin } from "@/api/widget";

export default {
    components: {
        NotOrcidDialog,
    },
    data() {
        var userName2 = (rule, value, callback) => {
            const mailReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;

            if (!value) {
                return callback(new Error('Please enter your phone number.'))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('Please enter the correct phone number.'))
                }
            }, 100)
        };
        return {
            isSend: false,
            timeCount: 120,
            btnText: "Send Verification Code",
            dialogVisible: false,
            width: "",
            //是否登录状态，这里并不重要
            isLogin: this.$store.getters.getIsLogin,
            radioIndex: 0,
            sel: "author",
            radio: ["author", "editor"],
            registrationShow: true,
            type: "/login",
            loginForm: {
                eMail: "",
                userPassword: ""
            },
            //注册form
            form: {
                userName: "",
                eMail: "",
                phone: "",
                verificationCode: "",
                researchDirection: "",
                publisher: "",
                journal: "",
                role: "",
                userPassword: "",
                againPassWord: "",
            },
            rules: {
                email: [{
                    required: true,
                    trigger: "blur",
                    validator: "Please enter email"
                }],
                userName: [{
                    trigger: "blur",
                    validator: userName2,
                },],
                userPassword: [{
                    required: true,
                    trigger: "blur",
                    message: "Please enter password"
                }],
                code: [{
                    required: true,
                    trigger: "blur",
                    message: "Please enter the verification code"
                }],
            },
        };
    },
    methods: {
        openDialog() {
            this.dialogVisible = true
        },
        closeDialog() {
            this.dialogVisible = false
        },
        orcidLoginFun() {
            orcidLogin().then(res => {
                // 截取 res.data 中的state
                const state = res.data.split('state=')[1].split('&')[0];
                // 将state存入cookie中
                localStorage.setItem('orcid_state', state);
                // 打开新窗口
                window.open(res.data, '_blank', 'height=800,width=800,top=240,left=560,status=yes,toolbar=no,menubar=no,resizable=yes,scrollbars=no,location=no,titlebar=no');
            })
        },
        receiveMessageFromChild(message) {
            console.log('收到子窗口的信息:', message);

            this.$store.commit("setUserInfo", message);
            this.$store.commit("setTokenInfo", message.accessToken);
            this.$store.commit("setExpireTime", message.expiresIn);
            this.$store.commit("setIsLogin", true);

            this.$loginDialog.closeDialog()

            if (message.userId == -1) {
                // 未注册
                this.$refs.notOrcidDialog.openDialog(message);
            } else {
                // 已注册
                this.getUserInfoById(message.userId);
            }
        },
        radioBtn(index) {
            this.form = {}
            this.radioIndex = index
        },
        registrationBtn() {
            this.registrationShow = false
            this.form = {}
        },
        //author注册接口
        signBtn() {

            this.form.identity = this.sel
            const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            const phoneReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;

            if (this.form.userName == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter your username first!",
                });
                return false
            }
            if (this.form.eMail == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter your email first!",
                });
                return false
            }

            if (!mailReg.test(this.form.eMail)) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "The mailbox format is incorrect.",
                });
                return false
            }
            if (this.form.phone == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter the phone number first.",
                });
                return false
            }
            if (!phoneReg.test(this.form.phone)) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter the phone number first.",
                });
                return false
            }
            if (this.form.userPassword.length < 4) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Password must be longer than three digits.",
                });
                return false;
            }

            const params = {
                ...this.form,
                name: this.form.userName,
                email: this.form.eMail,
                account: this.form.eMail,
                password: Crypto.SHA1(Crypto.MD5(this.form.userPassword).toString()).toString(),
                emailCode: this.form.verificationCode,
                code: JSON.stringify({
                    researchDirection: this.form.researchDirection,
                })
            }

            $http.loginTrue("/contrimetric-auth/register/userByEmail", params, true).then(res => {
                if (res.code == 200) {
                    this.$store.commit("setUserInfo", res.data);
                    this.$store.commit("setTokenInfo", res.data.accessToken);
                    this.$store.commit("setExpireTime", res.data.expiresIn);
                    this.$store.commit("setIsLogin", true);

                    const userId = res.data.userId

                    this.getUserInfoById(userId)

                    this.$loginDialog.closeDialog()
                }
            }).catch(err => {
                console.log(err);
            })
        },
        //登录接口
        loginBtn() {
            const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            if (this.loginForm.eMail == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter your email first.",
                });
                return false
            }
            if (!mailReg.test(this.loginForm.eMail)) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Email format is incorrect.",
                });
                return false
            }
            if (this.loginForm.userPassword.length < 4) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Password must be longer than four digits.",
                });
                return false;
            }

            const params = {
                account: this.loginForm.eMail,
                password: this.loginForm.userPassword,
            }

            $http.postOrcidP('/contrimetric-auth/token', params, true).then(res => {
                this.$store.commit("setUserInfo", res.data)
                this.$store.commit("setTokenInfo", res.data.accessToken)
                this.$store.commit("setExpireTime", res.data.expiresIn)
                this.$store.commit("setIsLogin", true)

                this.$loginDialog.closeDialog()

                const userId = res.data.userId

                this.getUserInfoById(userId)

            }).catch(err => {
                console.log(err);
            })
        },
        //获取验证码
        getCode() {
            if (this.isSend) return

            const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            const phoneReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
            if (this.form.userName == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter your username first.",
                });
                return false
            }
            if (this.form.eMail == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter your email first.",
                });
                return false
            }
            if (!mailReg.test(this.form.eMail)) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Email format is incorrect.",
                });
                return false
            }
            if (this.form.phone == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Please enter the phone number first.",
                });
                return false
            }
            if (!phoneReg.test(this.form.phone)) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "The phone number format is incorrect.",
                });
                return false
            }
            if (this.form.userPassword.length < 4) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "Password must be longer than three digits.",
                });
                return false;
            }
            $http.loginTrue('/contrimetric-auth/sendVerification/contrimetricsVerificationCode', this.form, true).then(res => {
                this.isSend = true;
                this.$message.success('The verification code is sent successfully. Procedure')

                const timer = setInterval(() => {
                    this.timeCount -= 1;
                    if (this.timeCount === 0) {
                        clearInterval(timer);
                        this.isSend = false;
                        this.timeCount = 120;
                    }
                }, 1000);
            }).catch(err => {
                console.log(err);
            })
        },
        getUserInfoById(id) {
            $http.getOrcidP('/contrimetric-user/simpleInfo', { id }, true).then(res => {
                const simUserInfo = res.data;
                localStorage.setItem('simUserInfo', JSON.stringify(simUserInfo));

                this.$message.success({
                    showClose: true,
                    duration: 1500,
                    message: "Login successful",
                });
            }).catch(err => {
                console.log(err);
            })
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.width = width < 768 ? '90%' : '700px';
        },
    },
    mounted() {
        window.receiveMessageFromChild = this.receiveMessageFromChild;

        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },

};
</script>

<style lang="scss" scoped>
.orcid_register {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .orcid_login {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.login_logo {
    margin-top: 40px;

    img {
        &:hover {
            cursor: pointer;
        }
    }
}

.se43 {
    height: 251px;
}

@media (max-width: 768px) {
    .se43 {
        height: 175px;

        .login_logo {
            img {
                width: 45%;
                margin-left: 15px;
            }
        }
    }
}

.index-meeting .el-image__inner {
    vertical-align: bottom !important;
}
</style>
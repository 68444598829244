import axios from 'axios';
import qs from 'qs'
// import {
// 	ElMessage,
// 	ElLoading
// } from 'element-plus'

import { Message, Loading } from 'element-ui';

let loadingInstance = null;
//创建loading
const options = {
	fullscreen: true,
	target: ""
}
// 创建axios实例
const Axios = axios.create({
	// baseUrl:"/api",
	// timeout:50000,
	// headers:{
	// 	'content-type': 'application/json'
	// }
});


Axios.defaults.withCredentials = true //让ajax默认携带cookie
// Axios.defaults.timeout = 50000; // 超时时间
Axios.defaults.timeout = 500000; // 超时时间 500秒
Axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.responseType = 'json'; // 返回数据类型

Axios.interceptors.request.use(config => {
	// config.headers['satoken'] = sessionStorage.getItem("tokenInfo") == "" || sessionStorage.getItem(
	// 	"tokenInfo") == null ? "" : JSON.parse(sessionStorage.getItem("tokenInfo")).tokenValue

	const token = localStorage.getItem("tokenInfo") || ""
	if (token) config.headers['contrimetric-auth'] ="bearer " + token.slice(1, -1)

	if (!config.headers['Content-Type']) {
		if (config.method === "post" || config.method === "get" || config.method === "delete") {
			// 序列化
			// config.data = qs.stringify(config.data);
		}
	}
	if (config.loading != "") {
		options.target = config.loading
		loadingInstance = Loading.service(options);
	}
	return config;
}, error => {
	let msg = "请求数据失败！";
	Message.warning({
		message: msg,
		type: 'warning'
	});
	return Promise.reject(msg)
});

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(res => {
	console.log("123sadascfz++++====>", res);
	// let resData = res.data;
	// if(loadingInstance!=null){
	// 	loadingInstance.close();
	// }
	// if (resData.code == 200) {
	// 	return resData;
	// } else {
	// 	ElMessage.error({
	// 		message: resData.msg,
	// 		type: 'warning'
	// 	});
	// 	return Promise.reject(resData.msg)
	// }

	//过滤器，从code改成了status
	let resData = res.data;
	if (loadingInstance != null) {
		loadingInstance.close();
	}
	if (resData.code == 200) {
		return resData;
	} else {
		Message.error({
			message: resData.msg,
			type: 'warning'
		});
		return Promise.reject(resData.msg)
	}
}, error => {
	if (loadingInstance != null) {
		loadingInstance.close();
	}
	Message.error({
		message: res.data.msg,
		type: 'warning'
	});
});

export default Axios;
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"footer-content"},[_c('div',{staticClass:"footer-row"},[_c('div',{staticClass:"footer-col"},[_c('div',{staticClass:"logo-section"},[_c('img',{staticClass:"logo-img",attrs:{"src":require('@/assets/images/logo_copyright.png')}}),_c('p',{staticClass:"description-text"},[_vm._v(" Contrimetric is shortened from Contribution Metric, which is called Article Citation Contribution Indicator(ACCI), or Contribution Factor(CF). Each academic paper has two ACCI values: ACCI-I(CF1) and ACCI-II(CF2), which is a new designed indicator for scientific contribution. ")])]),_c('div',{staticClass:"bottom-download",on:{"click":_vm.downloadLogoZip}},[_vm._m(0),_vm._m(1)])]),_c('div',{staticClass:"footer-col contact-info"},[_c('div',{staticClass:"contact-section"},[_vm._m(2),_c('div',{staticClass:"contact-detail"},[_c('img',{staticClass:"image1",attrs:{"src":require('@/assets/images/position.png'),"alt":""}}),_c('span',[_vm._v("8 Eu Tong Sen Street,#16-81,The Central,Singapore 059818")])]),_c('div',{staticClass:"contact-detail"},[_c('img',{staticClass:"email-icon",attrs:{"src":require('@/assets/images/email.png'),"alt":""}}),_vm._m(3)])])]),_c('div',{staticClass:"footer-col social-media"},[_c('div',{staticClass:"social-section"},[_vm._m(4),_c('p',{staticClass:"social-text"},[_vm._v(" Please follow our activities on the following social media platforms ")]),_c('MediaLinks'),_vm._m(5)],1)])]),_c('div',{staticClass:"copyright-box"},[_vm._m(6),_c('div',{staticClass:"bottom-middle-text"},[_c('span',{on:{"click":function($event){return _vm.goToPlus('/terms')}}},[_vm._v("Terms of use")]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.goToPlus('/privacy')}}},[_vm._v("Privacy policy")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-image"},[_c('img',{attrs:{"src":require("../assets/images/download.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-text"},[_c('div',[_vm._v("Download")]),_c('div',[_vm._v("Contrimetric Logo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-title"},[_c('strong',[_vm._v("CONTACT INFO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a',{staticClass:"contact-link",attrs:{"href":"mailto:794532995@qq.com?cc=123456@qq.com&bcc=7890@qq.com&subject=Hello%20World&body=","target":"_top"}},[_vm._v(" contact@contrimetric.com ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-title social-title"},[_c('strong',[_vm._v("SOCIAL MEDIA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iso-box"},[_c('img',{attrs:{"src":require("../assets/iso.webp"),"alt":""}}),_c('span',[_vm._v(" The widget and other AI tools have been certified by ISO 27001: 2022 Information Security Management System. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Copyright © 2025 All Rights Reserved by "),_c('span',{staticClass:"highlight"},[_vm._v("Contrimetric")])])
}]

export { render, staticRenderFns }
import router from "./router";
import store from "./store";

import "./assets/css/style.css";
import "./assets/layui/css/layui.css"

import axios from "axios";

import Vue from "vue";

// import ECharts from "vue-echarts";
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";
import { Callbacks } from "jquery";

import ElementUI from 'element-ui';
import './element-variables.scss';
import i18n from '@/i18n/index'

import LoginDialog from "./views/loginDialog.vue"

Vue.config.productionTip = false

Vue.prototype.$store = store
Vue.prototype.$axios = axios;
//.use(axios)

Vue.use(router)
	.use(ElementUI)
	.use(VueClipboard).use(ElementUI, {
		i18n: (key, value) => i18n.t(key, value)
	})

const dialogInstance = new Vue({
	render: (h) => h(LoginDialog),
}).$mount();

document.body.appendChild(dialogInstance.$el);

// 注册全局指令
Vue.directive('openLoginDialog', {
	inserted(el, binding) {
		el.addEventListener('click', () => {
			dialogInstance.$children[0].openDialog();
		});
	},
});

Vue.directive('closeLoginDialog', {
	inserted(el, binding) {
		el.addEventListener('click', () => {
			dialogInstance.$children[0].closeDialog();
		});
	},
});

Vue.prototype.$loginDialog = dialogInstance.$children[0];

new Vue({
	render: h => h(App),
	i18n,
	router,
	store
}).$mount('#app')

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})
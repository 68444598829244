<template>
	<article style="background:repeating-linear-gradient(to right,#202E43,#254E5F,#276268,#0C8A8D);height: 45px;">
		<div class="container-1200 mh111">
			<div class="layui-fluid" style="height: 40px;">
				<div class="layui-row" style="color: white;line-height: 45px;">
					<div class="layui-col-md6 page_tips">
						Welcome to Contrimetric!
					</div>
					<div class="layui-col-md6 layui-col-right float-hand" v-show="!isLogin">
						<span  style="color: white" v-openLoginDialog>Log in</span>
					</div>
					<div class="layui-col-md6 layui-col-right" v-if="isLogin">
						<p><span class="username" style="margin-right: 30px">
								{{ $store.getters.getUserInfo.userName }}
							</span><span class="float-hand logout" @click="loginOut">Log out</span></p>
					</div>
				</div>
			</div>
		</div>
		<section class="header-bg" style="min-height: 750px;">
			<div class="container-1200" style="padding-top: 40px;">
				<div class="layui-fluid">
					<div class="logo_sdf" style="display: flex;align-items: center;">
						<div class="logo_image" @click="goToHome">
							<img :src="require('@/assets/images/logo.png')" />
						</div>
						<div id="warp-menu" style="margin:25px 0px 0px 295px;">
							<Menu></Menu>
						</div>

						<div class="menu_btns" @click="() => drawer = true">
							<img src="../assets/images/daohang.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</section>

		<el-drawer title="" :visible.sync="drawer" :direction="'ttb'" :size="'100%'">
			<template #title>
				<div class="menu_logo" @click="goToHome">
					<img :src="require('@/assets/images/logo_white.jpg')" />
				</div>
			</template>
			<MenuList @closeDrawer="closeDrawer"></MenuList>
		</el-drawer>
	</article>
</template>

<script>
import $http from "@/request/http";
import Menu from "./menu.vue"
import MenuList from "./menuList.vue";

export default {
	name: "app-header",
	components: {
		Menu,
		MenuList
	},
	data() {
		return {
			//是否登录
			isLogin: this.$store.getters.getIsLogin,
			userInfo: this.$store.getters.getTokenInfo,
			routerR: true,
			drawer: false
		};
	},
	watch: {
		//监听登录状态
		"$store.getters.getIsLogin": {
			handler(isLogin) {
				this.isLogin = isLogin
			},
			immediate: true,
		},
	},
	methods: {
		loginOut() {
			this.$store.commit('setTokenInfo', "")
			this.$store.commit('setUserInfo', "")
			this.$store.commit('setIsLogin', "")
			localStorage.setItem('simUserInfo','')

			this.$message.success({
				showClose: true,
				duration: 1500,
				message: "success logout",
			});
		},
		goToHome() {
			this.closeDrawer()
			this.$router.push('/index');
		},
		closeDrawer() {
			this.drawer = false
		},
		// goToLogin() {
		// 	// this.$router.push('/login');
		// 	console.log(this);
			
		// }
	},
};
</script>

<style>
body>.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
	background-color: #1da6b8;
	color: #fff !important;
}
</style>
<style lang="scss">
.mh111 {
	.layui-fluid {
		padding: 0;

		.layui-row {
			display: flex;
			justify-content: space-between;

			&::after {
				display: none;
			}

			&::before {
				display: none;
			}
		}
	}
}

.header-bg .logo_image {
	&:hover {
		cursor: pointer;
	}
}

.menu_btns {
	display: none;

	img {
		width: 30px;
	}
}

.menu_logo {
	img {
		width: 160px;
	}
}

@media (max-width: 1200px) {
	#warp-menu {
		display: none;
	}

	.logo_image {
		img {
			width: 70%;
		}
	}

	.menu_btns {
		display: block;
	}

	.logo_sdf {
		display: flex;
		justify-content: space-between;
	}

	.page_tips {
		font-size: 12px;
	}
}
</style>
import $http from "@/request/http";

// 期刊查询
export const getJournalList =  (params) => $http.getFhl("/journal/journalPage", params);

// 申请使用期刊表单
export const applyJournal = (params) => $http.postFhl("/journal/inserJournalForm", params);

// 查询论文名列表
export const getPaperList = (params) => $http.getFhl("/journal/getLinkList", params);

// 查询使用期刊数量
// 导出一个函数，用于获取日志数量
export const getUseJournalCount = (params) => $http.getFhl("/journal/getCountNum", params);

// 期刊轮播
export const getJournalCarousel = () => $http.jdcAjax("/pushjournal/carousel");

// orcid 登录
export const orcidLogin = (params) => $http.get("/contrimetric-auth/custom-social/oauth/render/orcid", params);

// callback
export const orcidCallback = (params) => $http.postOrcidP("/contrimetric-auth/custom-social/oauth/callback/orcid", params);

// 绑定用户信息
export const bindUserInfo = (params) => $http.postOrcidP("/contrimetric-user/register-guest", params);

// 留言
export const leaveMessage = (params) => $http.postFhl("/journal/leaveMsg", params);
<template>
    <div class="menu_list_box">
        <el-collapse>
            <el-collapse-item title="About" name="About">
                <template slot="title">
                    <div class="menu_item_" @click="goToPage('/about')">About</div>
                </template>
                <div class="menu_item_" @click="goToPage('/researchers')">Researchers</div>
                <div class="menu_item_" @click="goToPage('/Contrimetrics')">Contrimetrics</div>
                <div class="menu_item_" @click="goToPage('/Publications')">Publications</div>
                <div class="menu_item_" @click="goToPage('/Contact')">Contact</div>
            </el-collapse-item>
        </el-collapse>

        <el-collapse>
            <el-collapse-item title="Solutions" name="Solutions">
                <div class="menu_item_" @click="goToPage('/recheck-ai')">ReCheck</div>
                <div class="menu_item_" @click="goToPage('/works')">Plugin</div>
                <div class="menu_item_" @click="goToPage('/ai')">AI</div>
            </el-collapse-item>
        </el-collapse>

        <el-collapse>
            <el-collapse-item title="Contributors" name="Contributors">
                <div class="menu_item_" @click="goToPage('/clients')">Clients</div>
                <div class="menu_item_" @click="goToPage('/codes')">Codes search</div>
                <div class="menu_item_" @click="goToPage('/requestDemo')">Request a Demo</div>
                <div class="menu_item_" @click="goToPage('/join')">Join Contrimetric Family</div>
                <div class="menu_item_" @click="goToPage('/newcase')">New Case</div>
            </el-collapse-item>
        </el-collapse>

        <div class="menu_item_" @click="goToPage('/help')">Support</div>

        <el-button type="primary" class="apply_btn" @click="goToPage('/requestDemo')">Request a
            Demo</el-button>
    </div>
</template>

<script>
import { validRoutes } from './userDefinedBanner/data';

export default {
    data() {
        return {
            validRoutes
        }
    },
    methods: {
        goToPage(page) {
            this.$emit('closeDrawer')
            if (validRoutes.includes(page)) return
            this.$router.push(page);
        }
    }
}
</script>

<style lang='scss'>
.menu_list_box {
    padding: 0 20px;
    padding-bottom: 20px;

    .el-collapse-item {
        .el-collapse-item__header {
            font-size: 16px;
            padding: 5px 0;
        }

        .el-collapse-item__content {
            .menu_item_ {
                margin-left: 20px;
            }
        }
    }

    .menu_item_ {
        font-size: 16px;
        padding: 20px 0;
    }

    .apply_btn {
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }
}
</style>
<template>
    <div class="bottom-right">
        <img :src="require('@/assets/images/f.png')" alt=""
            @click="goToWebPage('https://www.facebook.com/profile.php?id=61573013472571')" />
        <img :src="require('@/assets/images/fly.png')" alt="" @click="goToWebPage('https://x.com/Contrimetric25')" />
        <img :src="require('@/assets/images/in.png')" alt=""
            @click="goToWebPage('https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A106159990&keywords=Contrimetric&origin=ENTITY_SEARCH_HOME_HISTORY&sid=QJm')" />
        <img :src="require('@/assets/images/camera.png')" alt=""
            @click="goToWebPage('https://www.instagram.com/contrimetric/')" />
        <img :src="require('@/assets/images/ytb.png')" alt=""
            @click="goToWebPage('https://www.youtube.com/@Contrimetric')" />
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods:{
        goToWebPage(url) {
			if (url === "") return;
			window.open(url, "_blank");
		},
    }
}
</script>

<style lang='scss'>

.bottom-right {
	width: 200px;
	display: flex;
	justify-content: space-between;
	margin: 15px 0;

	img {
		width: 24px;
		height: 24px;
		transition: all 0.3s;

		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}
	}
}
</style>
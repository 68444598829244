<template>
	<div class="footer-container">
		<div class="footer-content">
			<div class="footer-row">
				<div class="footer-col">
					<div class="logo-section">
						<img :src="require('@/assets/images/logo_copyright.png')" class="logo-img" />
						<p class="description-text">
							Contrimetric is shortened from Contribution Metric, which is
							called Article Citation Contribution Indicator(ACCI), or
							Contribution Factor(CF). Each academic paper has two ACCI values:
							ACCI-I(CF1) and ACCI-II(CF2), which is a new designed indicator
							for scientific contribution.
						</p>
					</div>
					<div class="bottom-download" @click="downloadLogoZip">
						<div class="download-image">
							<img src="../assets/images/download.png" alt="" />
						</div>
						<div class="download-text">
							<div>Download</div>
							<div>Contrimetric Logo</div>
						</div>
					</div>
				</div>

				<div class="footer-col contact-info">
					<div class="contact-section">
						<div class="bottom-title"><strong>CONTACT INFO</strong></div>
						<div class="contact-detail">
							<img :src="require('@/assets/images/position.png')" alt="" class="image1" />
							<!-- <span>B-7-28 Horizon Suites Blok B, Kota Warisan 43900 Sepang, Selangor, Malaysia</span> -->
							<span>8 Eu Tong Sen Street,#16-81,The Central,Singapore 059818</span>
						</div>
						<div class="contact-detail">
							<img :src="require('@/assets/images/email.png')" alt="" class="email-icon" />
							<span>
								<a class="contact-link"
									href="mailto:794532995@qq.com?cc=123456@qq.com&bcc=7890@qq.com&subject=Hello%20World&body="
									target="_top">
									contact@contrimetric.com
								</a>
							</span>
						</div>
					</div>
				</div>

				<div class="footer-col social-media">
					<div class="social-section">
						<div class="bottom-title social-title">
							<strong>SOCIAL MEDIA</strong>
						</div>
						<p class="social-text">
							Please follow our activities on the following social media
							platforms
						</p>

						<MediaLinks />

						<div class="iso-box">
							<img src="../assets/iso.webp" alt="" />
							<span>
								The widget and other AI tools have been certified by ISO 27001: 2022 Information
								Security Management System.
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="copyright-box">
				<div>
					Copyright © 2025 All Rights Reserved by
					<span class="highlight">Contrimetric</span>
				</div>
				<div class="bottom-middle-text">
					<span @click="goToPlus('/terms')">Terms of use</span> |
					<span @click="goToPlus('/privacy')">Privacy policy</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MediaLinks from "@/components/mediaLinks/index.vue"

export default {
	name: "app-footer",
	components: {
		MediaLinks
	},
	methods: {
		goToPlus(path) {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			this.$router.push(path);
		},
		downloadLogoZip() {
			const fileUrl = "https://contrimetric.com/logo/Contrimetric_logo.zip";
			const link = document.createElement("a");
			link.href = fileUrl;
			link.download = "Contrimetric_logo.zip";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	},
};
</script>

<style scoped lang="scss">
.footer-container {
	background-image: url(../assets/images/bgBottom.png);
	min-height: 300px;
	color: #fff;
}

.footer-content {
	max-width: 1200px;
	margin: 40px auto 0;
	padding: 0 15px;
}

.footer-row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0;
}

.footer-col {
	width: 30%;
}

.logo-section {
	margin-top: 50px;
	line-height: 24px;
	text-align: justify;
}

.description-text {
	margin-top: 20px;
	color: hsl(220deg, 1.86%, 68.43%);
}

.bottom-download {
	display: flex;
	align-items: center;
	background-color: white;
	width: 200px;
	padding: 10px 20px;
	border-radius: 6px;
	transition: all 0.3s;
	margin-top: 30px;
	color: #5f5f5f;

	&:hover {
		cursor: pointer;
		filter: opacity(0.8);
	}
}

.download-image {
	width: 50px;
	margin-right: 6px;

	img {
		width: 100%;
		height: 100%;
	}
}

.download-text {
	font-weight: bold;
	font-size: 15px;
}

.contact-info {
	margin-left: 80px;
}

.contact-section {
	margin-top: 78px;
	line-height: 24px;
}

.contact-detail {
	display: flex;
	color: hsl(220deg, 1.86%, 68.43%);
	align-items: flex-start;
	margin: 5px 0;

	.image1 {
		margin: 6px 5px 0 0;
		width: 22px;
		height: 20px;
	}
}

.email-icon {
	margin: 6px 5px 0 0;
	width: 17px;
	height: 13px;
}

.contact-link {
	color: hsl(220, 1.86%, 68.43%);
	margin-left: 4px;
}

.social-media {
	margin-left: 80px;
}

.social-section {
	margin-top: 96px;
	line-height: 24px;
}

.social-title {
	color: hsl(182.4deg, 64.1%, 92.35%);
}

.social-text {
	color: hsl(220deg, 1.86%, 68.43%);
	margin-top: 20px;
}

.bottom-title {
	font-size: 26px;
	color: hsl(182.4deg, 64.1%, 92.35%);
	margin-bottom: 20px;

	strong {
		display: block;
		width: 230px;
		padding: 18px 0;
		border-bottom: 1px dotted hsl(182.4deg, 64.1%, 92.35%);
	}
}

.copyright-box {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	margin-top: 40px;
	margin-right: 177px;
}

.highlight {
	color: aqua;
}

.bottom-middle-text {
	span {
		transition: all 0.3s;

		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}
	}
}

@media (max-width: 1200px) {

	.contact-info,
	.social-media {
		margin-left: 0;
	}
}

@media (max-width: 768px) {
	.footer-container {
		.footer-content {
			margin-top: 0;
		}

		.footer-row {
			flex-direction: column;
		}

		.logo-section {
			margin-top: 30px;
		}

		.logo-img {
			width: 40%;
		}

		.footer-col {
			width: 100%;
		}

		.contact-info,
		.social-media {
			margin-left: 0;
		}

		.bottom-download {
			width: 100%;
			box-sizing: border-box;

			.download-image {
				width: 25px;
			}

			.download-text {
				font-size: 13px;
			}
		}

		.bottom-title {
			strong {
				font-size: 22px;
			}
		}

		.contact-section,
		.social-section {
			margin-top: 28px;
		}

		.copyright-box {
			margin-right: 0;
			flex-direction: column;

			.bottom-middle-text {
				margin-top: 10px;
				margin-bottom: 20px;
			}
		}

		.iso-box {
			img {
				width: 24%;
			}

			span {
				align-self: center;
			}
		}
	}
}

.iso-box {
	display: flex;

	img {
	object-fit: contain;
		width: 26%;
		margin-right: 4%;
	}

	span {
		word-break: break-all;
	}
}
</style>

// import {
// 	createRouter,
// 	createWebHistory
// } from "vue-router"; //createWebHashHistory
// import home from "../views/index.vue";

import Vue from 'vue'

import VueRouter from 'vue-router'
import home from "../views/index.vue";

import { NODATA_PAGE_NAME, DATA_PAGE_NAME } from '../views/widget/data';

Vue.use(VueRouter)

const routes = [

	{
		path: "",
		name: "",
		component: () => import("../views/index.vue"),
	},
	{
		path: "/index",
		name: "index",
		component: home,
		meta: {
			index: 1,
			title: 'contrimetric_home'
		},
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../views/login.vue"),
		meta: {
			index: 2,
			title: 'contrimetric_login'
		},
	},
	// {
	// 	path: "/documents",
	// 	name: "documents",
	// 	component: () => import("../views/journal/documents.vue"),
	// 	meta: {
	// 		index: 3,
	// 		title: 'contrimetric_documents'
	// 	},
	// },
	{
		path: "/author",
		name: "author",
		component: () => import("../views/author.vue"),
		meta: {
			index: 4,
			title: 'contrimetric_author'
		},
	},
	{
		path: "/article",
		name: "article",
		component: () => import("../views/article.vue"),
		meta: {
			index: 4,
			title: 'contrimetric_article'
		},
	},
	{
		path: "/verify",
		name: "verify",
		component: () => import("../views/verify.vue"),
		meta: {
			index: 4,
			title: 'contrimetric_verify'
		},
	},
	{
		path: "/about",
		name: "about",
		component: () => import("../views/about.vue"),
		meta: {
			index: 5,
			title: 'contrimetric_about'
		},
	},
	{
		path: "/Contrimetrics",
		name: "Contrimetrics",
		component: () => import("../views/aboutNew.vue"),
		meta: {
			index: 5,
			title: 'contrimetric_Contrimetrics'
		},
	},
	{
		path: "/requestDemo",
		name: "requestDemo",
		component: () => import("../views/requestDemo.vue"),
		meta: {
			index: 5,
			title: 'contrimetric_requestDemo'
		},
	},
	{
		path: "/researchers",
		name: "researchers",
		component: () => import("../views/researchers.vue"),
		meta: {
			index: 5,
			title: 'contrimetric_researchers'
		},
	},
	{
		path: "/addVerify",
		name: "addVerify",
		component: () => import("../views/addVerify.vue"),
		meta: {
			index: 7,
			title: 'contrimetric_addVerify'
		},
	},
	// {
	// 	path: "/journalSource",
	// 	name: "journalSource",
	// 	component: () => import("../views/journal/journalSource.vue"),
	// 	meta: {
	// 		index: 8,
	// 		title: 'contrimetric_journalSource'
	// 	},
	// },
	{
		path: "/journalDetails",
		name: "journalDetails",
		component: () => import("../views/journal/journalDetails.vue"),
		meta: {
			index: 9,
			title: 'contrimetric_journalDetails'
		},
	},
	{
		path: "/clients",
		name: "clients",
		component: () => import("../views/widget/customers.vue"),
		meta: {
			index: 10,
			title: 'contrimetric_customers'
		},
	},
	{
		path: "/works",
		name: "works",
		component: () => import("../views/widget/works.vue"),
		meta: {
			index: 10,
			title: 'contrimetric_works'
		},
	},
	{
		path: "/price",
		name: "price",
		component: () => import("../views/widget/price.vue"),
		meta: {
			index: 10,
			title: 'contrimetric_price'
		},
	},
	{
		path: "/newcase",
		name: "newcase",
		component: () => import("../views/widget/newcase.vue"),
		meta: {
			index: 10,
			showDefaultHeader: false,
			title: 'contrimetric_newcase'
		},
	},
	{
		path: "/" + NODATA_PAGE_NAME,
		name: NODATA_PAGE_NAME,
		component: () => import(`../views/widget/${NODATA_PAGE_NAME}.vue`),
		meta: {
			index: 10,
			showDefaultHeader: false,
			title: 'contrimetric_' + NODATA_PAGE_NAME
		},
	},
	{
		path: "/" + DATA_PAGE_NAME,
		name: DATA_PAGE_NAME,
		component: () => import(`../views/widget/${DATA_PAGE_NAME}.vue`),
		meta: {
			index: 10,
			showDefaultHeader: false,
			title: 'contrimetric_' + DATA_PAGE_NAME
		},
	},
	{
		path: "/join",
		name: "join",
		component: () => import("../views/widget/join.vue"),
		meta: {
			index: 11,
			title: 'contrimetric_join'
		},
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("../views/termsUse.vue"),
		meta: {
			index: 12,
			title: 'contrimetric_terms'
		},
	},
	{
		path: "/privacy",
		name: "privacy",
		component: () => import("../views/privacy.vue"),
		meta: {
			index: 12,
			title: 'contrimetric_privacy'
		},
	},
	{
		path: "/help",
		name: "help",
		component: () => import("../views/help/contactus.vue"),
		meta: {
			index: 13,
			title: 'contrimetric_help'
		},
	},
	{
		path: "/ai",
		name: "ai",
		component: () => import("../views/ai/index.vue"),
		meta: {
			index: 14,
			title: 'contrimetric_ai'
		},
	},
	{
		path: "/recheck-ai",
		name: "rcheck",
		component: () => import("../views/rcheck/index.vue"),
		meta: {
			index: 14,
			title: 'contrimetric_rcheck'
		},
	},
	{
		path: "/callback",
		name: "callback",
		component: () => import("../views/orcidCallback.vue"),
		meta: {
			index: 14,
			title: 'contrimetric_callback'
		},
	}
];
// const router = createRouter({
// 	// history: createWebHashHistory(),
// 	history: createWebHistory(),
// 	routes,
// });
const router = new VueRouter({
	mode: 'history',
	// history: createWebHashHistory(),
	// history: createWebHistory(),
	routes,
});

export default router;
